// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from 'components/device';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Accordion from 'components/Accordion';
import Qa from 'components/Qa';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import Components from 'components/CmsComponents/';
import { HeaderContext } from 'context/header';

import PromptServiceContent from 'components/promptService/promptServiceContent';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 0 0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    display: ${props => props.isDesktop && 'flex'};
    padding: 0 20px;
  }

  .cardsCdi {
    section {
      width: 100%;
      div {
        width: 100%;
        margin-left: 0;
        @media ${device.laptop} {
          display: flex;
        }
        div {
          > ul {
            width: 100%;
            @media ${device.laptop} {
              display: flex;
              flex-direction: column;
              margin: 0;
            }
            > li {
              width: 100%;
              margin-right: 0;
              > h2 {
                font-weight: 400;
                width: 65%;
                padding-bottom: 1.875rem;

                @media ${device.laptop} {
                  width: 100%;
                  margin: 0;
                  padding: 0;
                  padding-bottom: 1.875rem;
                }
              }
              :hover {
                box-shadow: unset;
              }
              :first-child {
                margin-right: 0;
                padding: 0 15px 0 0 !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                  border-bottom: 1px solid #c6c8cc;
                  border-radius: unset;
                }
              }
              :last-child {
                margin-right: 0;
                padding: 0 0 0 15px !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                }
              }
              @media ${device.laptop} {
                height: unset;
              }
              ul {
                grid-template-columns: 1fr;
                width: 100%;
                li {
                  margin-right: 0;
                  @media ${device.laptop} {
                    width: 100%;
                    height: unset;
                  }
                  p a {
                    display: contents;
                  }
                  h3 {
                    width: 100%;
                    > a {
                      :first-child {
                        @media ${device.laptop} {
                          display: flex;
                          width: 100%;
                        }
                        @media ${device.tablet} {
                          width: 100%;
                        }
                      }
                      :last-child {
                        @media ${device.laptop} {
                          top: 0;
                          right: 0;
                        }
                      }
                    }
                    @media ${device.laptop} {
                      width: 100%;
                      position: relative;
                    }
                  }
                }
                @media ${device.laptop} {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Part = styled.div`
  position: relative;
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  padding-bottom: ${props => props.paddingBottom && '70px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};
  margin-top: ${props => props.isMarginTopCustomizedMob && '4.375rem'};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: ${props =>
      props.displayFirstChild ? props.displayFirstChild : 'flex'};
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: ${props =>
      props.isMarginTopCustomizedMob ? '1.25rem !important' : '0'};
    margin: ${props => props.isMarginFullWidth && '0 -1.25rem'};
    margin-bottom: ${props => props.isMarginBottomCustomizedMob && '1.25rem '};
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.paddingBottom &&
    css`
      padding: 0 0 4.375rem;

      @media ${device.laptop} {
        padding: 0 0 1.875rem;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

const About = styled.div``;

const AboutColumn = styled.div`
  display: flex;
  gap: 1.875rem;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const AboutTitle = styled(ReactMarkdown)`
  margin-bottom: 3.125rem;

  h2 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: normal;
    color: #231f20;
    margin: 0;
  }

  @media ${device.laptop} {
    margin-bottom: 1.25rem;

    h2 {
      font-size: 1.125rem;
    }
  }
`;

const AboutContent = styled(ReactMarkdown)`
  column-count: ${props => !props.isNotColumnCount && 2};
  column-gap: ${props => !props.isNotColumnGap && '1.875rem'};
  column-fill: auto;
  flex: ${props => !props.isNotFlex && 1};
  color: #231f20;

  p {
    font-size: 1rem;
    line-height: 1.625rem;

    & + p {
      margin-top: 2rem;
    }
  }

  @media ${device.laptop} {
    column-count: unset;
    height: unset;

    p {
      font-size: 0.875rem;
      line-height: 1.625rem;
    }

    li {
      font-size: 0.875rem;
      line-height: 1.625rem;
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const RenderPage = (page, isDesktop) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  const components = page[0]?.components ?? [];

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  const aboutContent =
    getMarkdown(page, 'ckvtmvgq00emu0a74b4gwcqmu', true)
      .split(/(##.*\n)/i)
      .filter(el => el) || '';

  function GetAboutContent() {
    let content = aboutContent[1].split('[column]');
    if (content.length > 1) {
      return (
        <AboutColumn>
          {content.map(item => (
            <AboutContent isNotColumnCount isNotColumnGap children={item} />
          ))}
        </AboutColumn>
      );
    } else {
      return <AboutContent isNotFlex children={aboutContent[1]} />;
    }
  }

  const formattedQaPergunta = getMarkdown(
    page,
    'ckusazd2w0ipl0b22pgkyoxpz',
    true
  )
    .replace(/(<div id=.*[\n|\r]\n)/i, '')
    .split(/(##.*\n)/gim)
    .filter(el => el)
    .map((item, index) =>
      index % 2 === 0 ? `${item.replace(/\n/gim, '')}` : item
    )
    .slice(0, 6)
    .join('\n');

  const qaContent = {
    autor: [],
    doctors: [],
    title: null,
    pergunta: formattedQaPergunta || '',
  };

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / -2'} padding borderBottom>
          <About>
            <AboutTitle children={aboutContent[0]} />
            <GetAboutContent />
          </About>
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notPadding notLeft shortPadding>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckvtnethc0p5y0b7485me4ju2', true)}
            fragments={getMarkdown(page, 'ckvtnethc0p5y0b7485me4ju2')}
            padding="0"
            isBottom
            contentPaddingLaptop="0"
            boxContentPaddingBottomLaptop="0"
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckvtndkq80nzv0b74a8vsxrxn', true)}
            fragments={getMarkdown(page, 'ckvtndkq80nzv0b74a8vsxrxn')}
            padding="0"
            isDropdownDesktop
            containerPaddingDesk="0"
            containerPaddingLaptop="0"
            isPatologica
            contentFontSizeMob="0.875rem"
            containerMedicalTeamMarginTop
            facilitiesForYou
            breakLineBr
            notTitleBold
          />
        </Part>
        <Part gridColumn={'2 / -2'} isMarginBottomCustomizedMob>
          <Accordion
            markdownContent={getMarkdown(
              page,
              'ckvtnntk00wbr0a74rnt3tk4r',
              true
            )}
            theme={{
              title: {
                fontSize320: '1.125rem',
              },
            }}
          />
          <Accordion
            markdownContent={getMarkdown(
              page,
              'ckvtnqddk0x670a74tb65bqnw',
              true
            )}
            theme={{
              title: {
                fontSize320: '1.125rem',
              },
            }}
          />
          <Accordion
            markdownContent={getMarkdown(
              page,
              'ckvtnuz9s10vb0b74pd8ttr29',
              true
            )}
            theme={{
              title: {
                fontSize320: '1.125rem',
              },
            }}
          />
        </Part>
        {components.length >= 1 && (
          <Part
            gridColumn={'1 / -1'}
            isMarginFullWidth
            isMarginTopCustomizedMob
          >
            {components?.map(component => {
              return Components(component);
            })}
          </Part>
        )}
        {qaContent.pergunta && (
          <Part gridColumn={'2 / -2'} paddingBottom>
            <Qa
              title={'Perguntas Frequentes'}
              data={qaContent}
              showFaqLink="/faq/paciente/"
              showFaqLinkOnClick={() =>
                localStorage.setItem('page', 'cuidados-paliativos')
              }
            />
          </Part>
        )}
      </GridContainer>
    </>
  );
};

const PalliativeCare = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);
  const [, setRedirect] = useState(false);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
    setRedirect(true);
  });

  const getImage = getMarkdown(page, 'ckvtm6nz403ul0c139o2svjt5', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {RenderPage(page, isDesktop)}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckvtlj04gllrs0b28aaxtqbwq" }) {
                featuredMarketing(locales: [en, es, pt_BR]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  activeHighlight
                  highlight
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_HighlightContent {
                    id
                    name
                    type
                    backgroundColorInitial {
                      hex
                    }
                    backgroundColorFinal {
                      hex
                    }
                    title
                    customTitle
                    description
                    images {
                      handle
                      width
                      height
                      url
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                qa(locales: pt_BR) {
                  titulo
                  pergunta
                  autor
                  doctors(first: 1000) {
                    active
                    assignment
                    name
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <PalliativeCare page={response.gcms.site.pages} {...props} />;
      }}
    />
  );
};
